<template>
  <OutsideCard title="PASSWORD.RESET_TITLE">
    <p>
      {{ $t("PASSWORD.RESET_BODY") }}
    </p>
    <v-form
      v-on:submit.prevent
      ref="form"
      v-model="formValidation"
      lazy-validation
      @submit.prevent="requestReset"
    >
      <v-row>
        <v-col cols="12" class="mb-n3">
          <v-text-field
            :label="$t('PASSWORD.RESET_FORM_MAIL_LABEL')"
            filled
            v-model="username"
            :rules="requiredRules.concat(emailRules)"
          >
            <template v-slot:prepend-inner>
              <v-icon dense class="mr-2">far fa-envelope</v-icon>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <div class="text-right mt-3">
            <BaseButton @click="requestReset" :loading="isLoading" :disabled="isLoading">
              {{ $t("PASSWORD.RESET_FORM_BUTTON_LABEL") }}
            </BaseButton>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </OutsideCard>
</template>

<script>
import { mapActions } from "vuex";
import formValidation from "@/mixins/formValidation";
import OutsideCard from "@components/Layout/OutsideCard.vue";

export default {
  name: "RequestNewPasswordForm",

  mixins: [formValidation],

  components: {
    OutsideCard,
  },

  data() {
    return {
      isLoading: false,
      username: null,
    };
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    requestReset() {
      this.validate();

      this.$nextTick(async () => {
        if (!this.formValidation) {
          return;
        }

        this.isLoading = true;

        try {
          await this.$axios.auth.requestNewPassword(this.username);
          await this.$router.push({ name: "RequestNewPasswordSuccess" });
        } catch (err) {
          console.error(err);
          this.setValidationErrors(err);
        } finally {
          this.isLoading = false;
        }
      });
    },
  },
};
</script>
